import React from 'react';
import { Box, Button } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';

const ContextMenu = ({
    contextMenu,
    handleReply,
    handleDelete,
    isAdmin,
    selectedMessage,
    sendDirectMessage,
    user
}) => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: contextMenu?.mouseY,
                left: contextMenu?.mouseX,
                backgroundColor: '#333',
                boxShadow: 3,
                borderRadius: 1,
                zIndex: 1000,
                padding: 1,
                display: contextMenu ? 'block' : 'none',
                color: 'white'
            }}
        >
            <Button onClick={() => handleReply(selectedMessage)} sx={{ color: 'white' }}>
                Reply <ReplyIcon sx={{ color: 'white', marginLeft: '0.5rem' }} />
            </Button>
            {(isAdmin || selectedMessage?.userId === user?._id) && (
                <Button onClick={handleDelete} sx={{ color: 'white' }}>Delete</Button>
            )}
            {
                /*
                    <Button onClick={() => sendDirectMessage()}  sx={{ color: 'white'}}>
                        Direct Message
                    </Button>
                */
            }

        </Box>
    );
};

export default ContextMenu;