import React, {useState,useRef,useEffect,useCallback} from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import { io } from 'socket.io-client';
import ContextMenu from './ChatScreenComponents/ContextMenu';
import MessageList from './ChatScreenComponents/MessageList';
import MessageInput from './ChatScreenComponents/MessageInput';
import UploadProgress from './ChatScreenComponents/uploadProgress';

const ChatScreen = ({ selectedChannel, isAdmin }) => {
    const [message, setMessage] = useState('');
    const [user, setUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [contextMenu, setContextMenu] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [replyTo, setReplyTo] = useState(null);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const location = useLocation();
    
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const messagesEndRef = useRef(null);
    const isMobile = window.innerWidth < 750;
    const socket = useRef(null);

    const isDM = location.pathname.startsWith('/home/dm');

    const getUserData = useCallback(async () => {
        try {
            const response = await axios.get('https://api.estopia.net/api/user', {
                withCredentials: true
            });
            setUser(response.data);
        } catch (err) {
            console.error(err);
        }
    }, []);

    useEffect(() => {
        getUserData();
    }, [getUserData]);

    const loadMoreMessages = useCallback(() => {
        if (maxPage || loadingMessages || !selectedChannel || !user) {
            return;
        }

        if(messages && page === Math.floor(messages.length / 45)) {
            return
        }

        setLoadingMessages(true);

        axios.get(`https://api.estopia.net/api/messages/${isDM ? 'dm/' : ''}${selectedChannel._id}?page=${page}`, {
            withCredentials: true
        })
            .then(response => {
                if(response.data.length < 45) {
                    setMaxPage(true);
                }

                response.data.forEach((message) => {
                    if (message.media && message.media.length > 0) {
                        message.media.forEach((mediaId) => {
                            mediaId.mediaUrl = `https://api.estopia.net/api/messages/${isDM ? 'dm/' : ''}media/${mediaId.mediaId}/${message._id}`
                        });
                    }
                    if (message.replyTo) {
                        const replyMessage = response.data.find((msg) => msg._id === message.replyTo);
                        if (replyMessage) {
                            message.replyTo = replyMessage;
                        }
                    }
                });

                if (page === 1) {
                    setMessages(response.data);
                    scrollToBottom();
                } else {
                    setMessages(prevMessages => [...response.data, ...prevMessages]);
                }
        
                setLoadingMessages(false);
            })
            .catch(error => {
                console.error(error);
            });
    }, [page, selectedChannel, maxPage, loadingMessages, messages, user, isDM]);

    const addNewMessage = (message) => {
        if (message.media && message.media.length > 0) {
            message.media.forEach((mediaId) => {
                mediaId.mediaUrl = `https://api.estopia.net/api/}messages/${isDM ? 'dm/' : ''}media/${mediaId.mediaId}/${message._id}`
            });
        }

        if(isDM) {
            message.username = message.senderId === user._id ? user.username : selectedChannel.name.username;
        }

        if (message.replyTo) {
            const replyMessage = messages.find((msg) => msg._id === message.replyTo);
            if (replyMessage) {
                message.replyTo = replyMessage;
            }
        }

        setMessages((prevMessages) => [...prevMessages, message]);
    };

    useEffect(() => {
        loadMoreMessages();
    }, [page, loadMoreMessages, selectedChannel, user]);
    
    useEffect(() => {
        setMaxPage(false);
        setPage(1);
        setLoadingMessages(false);
        setMessages([]);
        scrollToBottom();
    }, [selectedChannel]);

    const handleScroll = (e) => {
        if (maxPage || loadingMessages) return;

        if (e.target.scrollTop < 50) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const handleSwipe = (e, message) => {
        const touch = e.changedTouches[0];
        const startX = touch.clientX;
        const startY = touch.clientY;

        const handleTouchMove = (moveEvent) => {
            const moveTouch = moveEvent.changedTouches[0];
            const moveX = moveTouch.clientX;
            const moveY = moveTouch.clientY;

            const diffX = moveX - startX;
            const diffY = moveY - startY;

            if (Math.abs(diffX) > Math.abs(diffY) && diffX > 50) {
                setReplyTo(message);
                document.removeEventListener('touchmove', handleTouchMove);
            }
        };

        document.addEventListener('touchmove', handleTouchMove);

        document.addEventListener('touchend', () => {
            document.removeEventListener('touchmove', handleTouchMove);
        }, { once: true });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenu) {
                setContextMenu(null);
                setSelectedMessage(null);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [contextMenu]);

    const scrollToBottom = () => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100); // Adding a slight delay can also help smooth the scroll effect.
    };

    useEffect(() => {
        if(page === 1){
            scrollToBottom();
        }
    }, [messages, page]);

    useEffect(() => {
        const handleImageLoad = () => {
            scrollToBottom();
        };
    
        const images = document.querySelectorAll('img');
        images.forEach((img) => {
            img.addEventListener('load', handleImageLoad);
        });
    
        return () => {
            images.forEach((img) => {
                img.removeEventListener('load', handleImageLoad);
            });
        };
    }, [messages, page]);

    const startRecording = () => {
        setIsRecording(true);
        audioChunksRef.current = [];
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        audioChunksRef.current.push(event.data);
                    }
                };
                mediaRecorderRef.current.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
                    const audioFile = new File([audioBlob], 'voice-message.wav', { type: 'audio/wav' });
                    setUploadedFiles((prevFiles) => [...prevFiles, audioFile]);
                    sendMessage();
                    // Stop all tracks to release the microphone
                    stream.getTracks().forEach(track => track.stop());
                };
                mediaRecorderRef.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        if (uploadedFiles.length + files.length > 16) {
            alert('You can only upload up to 16 files.');
            return;
        }

        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        const maxSize = 100 * 1024 * 1024; // 50 MB

        if (totalSize > maxSize) {
            alert('Total file size exceeds the 100MB limit.');
            return;
        }

        if (files.length > 0) {
            setUploadedFiles((prevFiles) => [...prevFiles, ...files]);
        }
    };

    useEffect(() => {
        // Retrieve the token from cookies
        const token = Cookies.get('chattoken');

        // Establish WebSocket connection with token
        socket.current = io('wss://api.estopia.net', {
            auth: {
                token: token,
            },
            withCredentials: true,
            transports: ['websocket', 'polling']
        });

        socket.current.on('connect', () => {
            console.log('WebSocket connection established');
            // Send a message to join the channel room
            socket.current.emit('join_channel', { channelId: selectedChannel._id });
            socket.current.emit('join_dm');
        });

        socket.current.on('new_message', (message) => {
            addNewMessage(message);
        })

        socket.current.on('new_dm', (dm) => {
            console.log('New DM:', dm);
        });

        socket.current.on('new_dmmessage', (message) => {
            // Set media URLs for the new message
            if (message.media && message.media.length > 0) {
                message.media.forEach((mediaId) => {
                    mediaId.mediaUrl = `https://api.estopia.net/api/messages/${isDM ? 'dm/' : ''}media/${mediaId.mediaId}/${message._id}`
                });
            }
            if (message.replyTo) {
                const replyMessage = messages.find((msg) => msg._id === message.replyTo);
                if (replyMessage) {
                    message.replyTo = replyMessage;
                }
            }

            // Append the new message to the existing messages
            setMessages((prevMessages) => [...prevMessages, message]);
        })

        socket.current.on('deleted_message', (messageId) => {
            // Remove the deleted message from the messages list
            setMessages((prevMessages) => prevMessages.filter((message) => message._id !== messageId._id));
        });

        socket.current.on('disconnect', () => {
            console.log('WebSocket connection closed');
        });

        socket.current.on('connect_error', (error) => {
            console.error('WebSocket error:', error);
        });

        // Clean up WebSocket connection on component unmount
        return () => {
            if (socket.current) {
                socket.current.disconnect();
            }
        };
    }, [selectedChannel]);

    const sendMessage = () => {
        console.log(uploadedFiles)
        if (message.trim() !== '' || uploadedFiles.length > 0) {
            // Add your send message logic here
            if (message.length > 2000) {
                alert('Message is too long, The maximum message length is 2000 characters');
                return;
            }

            if(uploadProgress > 0) {
                alert('Please wait for the upload to complete');
                return;
            }
    
            const formData = new FormData();
            formData.append('channelId', selectedChannel._id);
            formData.append('recipientId', selectedChannel?.name?._id);
            formData.append('content', message);
            formData.append('username', user.username);
            if (replyTo) {
                formData.append('replyTo', replyTo._id);
            }
            uploadedFiles.forEach((file) => {
                formData.append('uploads', file);
            });
    
            axios.post(`https://api.estopia.net/api/messages${isDM ? '/dm' : ''}`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    if(uploadedFiles && uploadedFiles.length > 0) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    } else {
                        setUploadProgress(0);
                    }
                }
            })
                .then(response => {
                    setMessage(''); // Clear the input field after sending the message
                    setUploadedFiles([]);
                    setReplyTo(null);
                    setUploadProgress(0); // Reset progress after upload is complete
                })
                .catch(error => {
                    console.error(error);
                    setUploadProgress(0); // Reset progress on error
                });
        }
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });
    };

    const handleContextMenu = (event, message) => {
        console.log('Context menu:', message);
        event.preventDefault();
        setSelectedMessage(message);
        setContextMenu({
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
        });
    };

    const handleDelete = () => {
        if(!window.confirm('Are you sure you want to delete this message?')) {
            return;
        }
        console.log('Delete message:', selectedMessage);
        axios.delete(`https://api.estopia.net/api/messages/${selectedMessage._id}`, {
            withCredentials: true
        })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });

        setMessages((prevMessages) => prevMessages.filter((message) => message._id !== selectedMessage._id));
        setContextMenu(null);
    };

    const handleProfileClick = (username) => {
        console.log(`Profile clicked: ${username}`);
        // Add profile click logic here
    };

    const handleReply = (message) => {
        setReplyTo(message);
    };

    return (
        <Box
            sx={{
                width: '100%',
                height: isMobile ? '100vh' : '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: 1,
                overflow: 'hidden',
            }}
        >
            <Typography
                variant="h4"
                textAlign="center"
                marginBottom={4}
            >
                {isDM ? selectedChannel.name.username : selectedChannel.name} Chat
            </Typography>
            <MessageList 
                messages={messages} 
                user={user}
                selectedMessage={selectedMessage}
                handleProfileClick={handleProfileClick}
                handleContextMenu={handleContextMenu}
                handleSwipe={handleSwipe}
                formatDate={formatDate}
                isMobile={isMobile}
                handleScroll={handleScroll}
                maxPage={maxPage}
                messagesEndRef={messagesEndRef}
            />
            <UploadProgress uploadProgress={uploadProgress} />
            
            { ( (selectedChannel.staffTextOnly && isAdmin) || !selectedChannel.staffTextOnly) &&
                <Box >
                    {replyTo && (
                        <Typography variant="body2" sx={{ color: '#D0ADF0', marginBottom: 1 }}>
                            Replying to {replyTo.username}
                            <Button onClick={() => setReplyTo(null)} sx={{ marginLeft: 1 }}>Cancel</Button>
                        </Typography>
                    )}
                    <MessageInput
                        message={message}
                        setMessage={setMessage}
                        sendMessage={sendMessage}
                        isRecording={isRecording}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        isMobile={isMobile}
                        handleFileChange={handleFileChange}
                    />
                </Box>
            }
            <ContextMenu
                contextMenu={contextMenu}
                handleReply={handleReply}
                handleDelete={handleDelete}
                isAdmin={isAdmin}
                selectedMessage={selectedMessage}
                user={user}
            />
            {uploadedFiles && uploadedFiles.length > 0 && (
                <Box sx={{ marginTop: 2 }}>
                    <Typography variant="h6">Uploaded Files:</Typography>
                    <List>
                        {uploadedFiles.map((file, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={file.name} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    );
};

export default ChatScreen;