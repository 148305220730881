import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DownloadIcon from '@mui/icons-material/Download';

const MessageList = ({
    user,
    messages,
    selectedMessage,
    handleContextMenu,
    handleSwipe,
    handleProfileClick,
    formatDate,
    isMobile,
    messagesEndRef,
    handleScroll,
    maxPage,
}) => {
    const [highlightedMessage, setHighlightedMessage] = useState(null);

    const renderers = {
        a: ({ href, children }) => (
            <a href={href} style={{ color: '#D0ADF0', textDecoration: 'none' }}>
                {children}
            </a>
        ),
        p: ({ children }) => (
            <Box component="span" sx={{ margin: 0 }}>
                {children}
            </Box>
        ),
        h1: ({ children }) => (
            <h1 style={{ margin: 0 }}>
                {children}
            </h1>
        ),
        h2: ({ children }) => (
            <h2 style={{ margin: 0 }}>
                {children}
            </h2>
        ),
        h3: ({ children }) => (
            <h3 style={{ margin: 0 }}>
                {children}
            </h3>
        ),
        h4: ({ children }) => (
            <h4 style={{ margin: 0 }}>
                {children}
            </h4>
        ),
        ul: ({ children }) => (
            <ul style={{ margin: 0, paddingLeft: '20px' }}>
                {children}
            </ul>
        ),
        ol: ({ children }) => (
            <ol style={{ margin: 0, paddingLeft: '20px' }}>
                {children}
            </ol>
        ),
        li: ({ children }) => (
            <li style={{ margin: '0 0 5px 0' }}>
                {children}
            </li>
        ),
        blockquote: ({ children }) => (
            <blockquote style={{ margin: 0, paddingLeft: '10px', borderLeft: '3px solid #FFFFFF' }}>
                {children}
            </blockquote>
        )

    };

    return (
        <Box
            sx={{
                flex: 1,
                overflowY: 'auto',
                padding: 2,
                boxShadow: 1,
                '&::-webkit-scrollbar': { display: 'none' },
                msOverflowStyle: 'none',
                maxHeight: !isMobile ? 'calc(100% - 270px)' : 'calc(100% - 200px)',
            }}
            onScroll={handleScroll}
        >
        {maxPage && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Typography sx={{ fontSize: '0.75rem', color: 'gray' }}>
                    You have reached the beginning of the chat
                </Typography>
            </Box>
        )}
        {user && messages && messages.map((message, index) => {
                if(message.senderId?._id){
                    if(message.senderId?._id === user._id){
                        message.username = user.username;
                    } else {
                        message.username = message.receiverId.username;
                    }
                }
                const currentMessageDate = new Date(message.createdAt).toLocaleDateString();
                const previousMessageDate = index > 0 ? new Date(messages[index - 1].createdAt).toLocaleDateString() : null;
                const showDateSeparator = currentMessageDate !== previousMessageDate;

                const previousMessageTime = index > 0 ? new Date(messages[index - 1].createdAt).getTime() : null;
                const currentMessageTime = new Date(message.createdAt).getTime();
                const timeDifference = previousMessageTime ? currentMessageTime - previousMessageTime : null;
                const ShowName = index === 0 || messages[index - 1].userId !== message.userId || timeDifference > 5 * 60 * 1000; // 5 minutes buffer
                const IsEdited = new Date(message.createdAt).getTime() !== new Date(message.updatedAt).getTime();

                return (
                    <React.Fragment key={index}>
                        {showDateSeparator && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                                <Typography sx={{ fontSize: '0.75rem', color: 'gray' }}>
                                    {formatDate(message.createdAt)}
                                </Typography>
                            </Box>
                        )}
                        <Box
                            id={message._id} // Assign the message ID as the element ID
                            sx={{
                                display: 'flex',
                                justifyContent: message.userId === user._id ? 'flex-end' : 'flex-start',
                                marginTop: ShowName ? 2 : 0,
                                textAlign: message.userId === user._id ? 'right' : 'left',
                                backgroundColor: message._id === selectedMessage?._id ? '#2A2A2A' : message._id === highlightedMessage?._id ? '#3A3A3A' : null
                            }}
                            onContextMenu={(e) => handleContextMenu(e, message)}
                            onTouchStart={(e) => handleSwipe(e, message)} // Add swipe gesture handler
                        >
                            <Box sx={{ maxWidth: '70%', paddingTop: ShowName ? 1 : 0, borderRadius: 1 }}>
                                {message.replyTo && (
                                    <Typography variant="body2" sx={{ color: '#D0ADF0', cursor: 'pointer' }} onClick={() => {
                                        const element = document.getElementById(message.replyTo?._id);
                                        if (element) {
                                            element.scrollIntoView({ behavior: 'smooth' });
                                            setHighlightedMessage(message.replyTo); // Set the highlighted message
                                            setTimeout(() => setHighlightedMessage(null), 3000); // Remove highlight after 3 seconds
                                        }
                                    }}>
                                        Replying to {message.replyTo.username}
                                    </Typography>
                                )}
                                {ShowName && (
                                    <Typography variant="h6" sx={{ wordBreak: 'break-word', fontWeight: 'bold', color: '#ffffff', margin: 0 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', margin: 0, justifyContent: message.userId === user._id ? 'flex-end' : 'flex-start' }}>
                                            <span onClick={() => handleProfileClick(message.username)} style={{ cursor: 'pointer', textDecoration: 'none' }} sx={{ '&:hover': { textDecoration: 'underline' } }}>
                                                {message.username}
                                            </span>
                                            <Typography sx={{ fontSize: '0.75rem', color: 'gray', marginLeft: 1 }}>
                                                {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} {IsEdited ? '(edited)' : ''}
                                            </Typography>
                                        </Box>
                                    </Typography>
                                )}
                                <Box sx={{ wordBreak: 'break-word', color: '#ffffff', margin: 0 }}>
                                    <Markdown components={renderers} remarkPlugins={[remarkGfm]} sx={{ margin: 0 }}>
                                        {message.content}
                                    </Markdown>
                                </Box>
                                <div>
                                    {message.media && message.media.length > 0 && (
                                        message.media.map((mediaItem, index) => (
                                            <div key={index}>
                                                {mediaItem.mediaType.startsWith('image/') ? (
                                                    <img src={mediaItem.mediaUrl} alt="Media" style={{ width: isMobile ? '50vw' : '450px', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', marginBottom: '8px' }} />
                                                ) : mediaItem.mediaType.startsWith('video/') ? (
                                                    <video src={mediaItem.mediaUrl} controls style={{ width: isMobile ? '50vw' : '550px', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', marginBottom: '8px' }} />
                                                ) : mediaItem.mediaType.startsWith('audio/') ? (
                                                    <audio src={mediaItem.mediaUrl} controls style={{ width: isMobile ? '50vw' : '350px', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', marginBottom: '8px' }} />
                                                ) : (
                                                    <a href={mediaItem.mediaUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', fontSize: '0.75rem', color: 'white', width: isMobile ? '50vw' : 'auto', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', padding: '8px', textDecoration: 'none', backgroundColor: '#333', marginBottom: '8px' }}>
                                                        <DownloadIcon sx={{ fontSize: '1rem', marginRight: '0.25rem' }} />
                                                        {mediaItem.mediaId} ({mediaItem.mediaType})
                                                    </a>
                                                )}
                                            </div>
                                        ))
                                    )}
                                </div>
                            </Box>
                        </Box>
                    </React.Fragment>
                );
            })}
            <div ref={messagesEndRef} />
        </Box>
    );
};

export default MessageList;